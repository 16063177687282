@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,700italic,400italic);

@import url(../../../public_html/assets/templates/prostamp/js/slick/slick.css);

@import url(../../../public_html/assets/templates/prostamp/js/slick/slick-theme.css);

.slicknav_btn {
  position: relative;
  display: block;
  vertical-align: middle;
  float: right;
  padding: 0.438em 0.625em 0.438em 0.625em;
  line-height: 1.125em;
  cursor: pointer;
}

.slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
  margin-top: 0.188em;
}

.slicknav_menu {
  *zoom: 1;
}

.slicknav_menu .slicknav_menutxt {
  display: block;
  line-height: 1.188em;
  float: left;
}

.slicknav_menu .slicknav_icon {
  float: left;
  width: 1.125em;
  height: 0.875em;
  margin: 0.188em 0 0 0.438em;
}

.slicknav_menu .slicknav_icon:before {
  background: transparent;
  width: 1.125em;
  height: 0.875em;
  display: block;
  content: "";
  position: absolute;
}

.slicknav_menu .slicknav_no-text {
  margin: 0;
}

.slicknav_menu .slicknav_icon-bar {
  display: block;
  width: 1.125em;
  height: 0.125em;
  border-radius: 1px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
}

.slicknav_menu:before {
  content: " ";
  display: table;
}

.slicknav_menu:after {
  content: " ";
  display: table;
  clear: both;
}

.slicknav_nav {
  clear: both;
}

.slicknav_nav ul {
  display: block;
}

.slicknav_nav li {
  display: block;
}

.slicknav_nav .slicknav_arrow {
  font-size: 0.8em;
  margin: 0 0 0 0.4em;
}

.slicknav_nav .slicknav_item {
  cursor: pointer;
}

.slicknav_nav .slicknav_item a {
  display: inline;
}

.slicknav_nav .slicknav_row {
  display: block;
}

.slicknav_nav a {
  display: block;
}

.slicknav_nav .slicknav_parent-link a {
  display: inline;
}

.slicknav_brand {
  float: left;
}

.slicknav_menu {
  font-size: 16px;
  box-sizing: border-box;
  background: #4c4c4c;
  padding: 5px;
}

.slicknav_menu * {
  box-sizing: border-box;
}

.slicknav_menu .slicknav_menutxt {
  color: #fff;
  font-weight: bold;
  text-shadow: 0 1px 3px #000;
}

.slicknav_menu .slicknav_icon-bar {
  background-color: #fff;
}

.slicknav_btn {
  margin: 5px 5px 6px;
  text-decoration: none;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  border-radius: 4px;
  background-color: #222;
}

.slicknav_nav {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 0.875em;
  list-style: none;
  overflow: hidden;
}

.slicknav_nav ul {
  list-style: none;
  overflow: hidden;
  padding: 0;
  margin: 0 0 0 20px;
}

.slicknav_nav .slicknav_row {
  padding: 5px 10px;
  margin: 2px 5px;
}

.slicknav_nav .slicknav_row:hover {
  border-radius: 6px;
  background: #ccc;
  color: #fff;
}

.slicknav_nav a {
  padding: 5px 10px;
  margin: 2px 5px;
  text-decoration: none;
  color: #fff;
}

.slicknav_nav a:hover {
  border-radius: 6px;
  background: #ccc;
  color: #222;
}

.slicknav_nav .slicknav_txtnode {
  margin-left: 15px;
}

.slicknav_nav .slicknav_item a {
  padding: 0;
  margin: 0;
}

.slicknav_nav .slicknav_parent-link a {
  padding: 0;
  margin: 0;
}

.slicknav_brand {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  padding: 7px 12px;
  height: 44px;
}

* {
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
}

body {
  height: auto !important;
  height: 100%;
  min-height: 100%;
  font: 14px "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000000;
  line-height: 20px;
}

#mw-fix {
  width: 100%;
  margin: 0 auto;
}

div {
  font-size: 14px;
}

main {
  display: block;
}

img {
  border: 0;
}

.float-left-left {
  float: left;
}

.float-right {
  float: right;
}

.clear-both {
  clear: both;
}

dl {
  margin: 10px 0 10px 0;
  overflow: hidden;
  zoom: 1;
}

dd {
  padding-left: 1em;
  margin-bottom: 1em;
}

*:focus {
  outline: none;
}

a {
  text-decoration: underline;
  font-size: 14px;
  color: #000000;
}

a:hover {
  text-decoration: none;
}

p {
  padding: 0 0 10px 0;
}

ul li,main ul li {
  list-style: none;
}

dfn {
  font-style: normal;
  font-weight: bold;
}

main ol > li {
  list-style-type: decimal;
  list-style-position: inside;
  margin-bottom: 5px;
}

main acronym {
  cursor: help;
  border-bottom: 1px dashed #c2272d;
}

.slider ul li {
  margin: 0;
  padding: 0;
}

.slider img,.slider video {
  width: 100%;
  max-width: 941px;
  height: auto;
  box-sizing: content-box;
}

caption,th,td {
  text-align: left;
  font-weight: normal;
  font-size: 14px;
}

table {
  border-collapse: collapse;
  border: none;
  width: 100%;
}

fieldset {
  border: none;
}

textarea {
  vertical-align: top;
}

h1,h2,h3,h4,h5,h6 {
  font-size: 20px;
  font-weight: normal;
  color: #10420b;
}

h1 {
  padding: 15px 0 15px 0;
  margin: 0;
}

.tm {
  background: #4c4c4c;
  height: 35px;
  position: relative;
}

.tm ul {
  padding: 0;
  margin: 0;
}

.tm ul li {
  display: inline-block;
}

.tm a {
  display: block;
  height: 35px;
  padding: 0 65px;
  font: bold 13px/35px "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  color: #FFFFFF;
}

.tm a:hover {
  background-color: #f3960a;
  text-decoration: none;
}

.search-menu-form input:not([type=submit]):not([type=file]):not([type=checkbox]),.search-menu-form button[type=submit] {
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 1rem;
  line-height: 2rem;
}

.search-menu-form button[type=submit] {
  min-width: 0;
  display: none;
}

.search-menu {
  margin: 1rem auto;
}

.slicknav_nav form {
  padding: 5px 10px;
  margin: 2px 5px;
}

.tm-ico {
  position: absolute;
  right: 13px;
  top: 0;
}

.tm-ico a {
  padding: 0 15px;
}

#head {
  position: relative;
  background-color: #fafcfa;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

#head .logo {
  width: 100%;
  max-width: 281px;
  height: 140px;
  text-align: center;
  background-color: #f9faf2;
}

.hbg {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.hbg > div {
  margin: 0 auto;
}

.hbg-1 {
  background: url(/assets/templates/prostamp/images/header_01.jpg) no-repeat;
  width: 100%;
  max-width: 326px;
  height: 140px;
}

.hbg-3 {
  width: 100%;
  max-width: 287px;
}

.zakaz {
  width: 310px;
  height: 140px;
  margin: 0 auto;
  z-index: 2;
  font-size: 14px;
}

.zakaz li {
  position: relative;
  display: inline-block;
  top: 38px;
  margin-left: 25px;
  height: 59px;
}

.zakaz li strong {
  font-size: inherit;
}

.zakaz li:first-child strong {
  color: #925233;
}

.zakaz li:nth-child(2) strong {
  color: #d1650f;
}

.zakaz li:before {
  content: ' ';
  position: absolute;
  background: url(/assets/templates/prostamp/images/map-bullet.png);
  width: 15px;
  height: 20px;
  top: 5px;
  left: -20px;
}

.zakaz li a {
  text-decoration: none;
}

.zakaz .zakaz-link {
  color: #000000;
  text-decoration: underline;
}

.zakaz .zakaz-link:hover {
  text-decoration: none;
}

.zakaz-phone {
  color: #21632f;
  font-size: inherit;
  font-weight: bold;
}

.zakaz-btn {
  display: inline-block;
  width: 240px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: white;
  background-color: #f3960a;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  margin: 1rem auto;
}

main {
  max-width: 940px;
  padding: 0 30px;
}

main section {
  padding: 28px 0 38px 0;
  border-bottom: 2px solid #f5f5f5;
}

main section:first-of-type {
  padding-top: 0;
}

main section:last-of-type {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 1em;
}

main h1 {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #7f7f7f;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3em;
  text-transform: uppercase;
}

main h2 {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #7f7f7f;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3em;
  text-transform: uppercase;
  line-height: 30px;
  margin-bottom: 23px;
}

main .menu li {
  list-style: none;
  background: url(/assets/templates/prostamp/images/bullet.png) no-repeat;
  padding-left: 25px;
}/*
 *main section:after {
 *    content: " "
 *    display: block
 *    clear: both
 *}
 */.bulleted li {
  list-style: none;
  background: url(/assets/templates/prostamp/images/bullet.png) no-repeat;
  padding-left: 25px;
}

main .menu li {
  width: 30%;
  float: left;
  line-height: 1em;
  margin-bottom: 24px;
  min-height: 40px;
}

main .menu li a {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

main .slider {
  width: 100%;
  max-width: 941px;
  height: 201px;
  overflow-y: hidden;
}

main .slider .slick-arrow {
  display: none !important;
}

#content {
  clear: both;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}

#content .l-col {
  padding: 0 0 441px 0;
  background: url(/assets/templates/prostamp/images/chel.jpg) bottom no-repeat #f9faf2;
  vertical-align: top;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 222px;
      -ms-flex: 0 0 222px;
          flex: 0 0 222px;
}

#content .c-col {
  padding: 23px 25px 23px 25px;
  vertical-align: top;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

#menu {
  margin: 15px 14px 27px 14px;
}

#menu li {
  border-bottom: 1px dotted #fe9705;
  padding: 13px 0 13px 20px;
  margin: 0;
}

#menu li a {
  font: bold 14px "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #0f6706;
}

#menu li a:hover {
  font: bold 14px "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #0f6706;
}

.left-menu {
  background: #f9faf2;
  margin-top: 16px;
  margin-bottom: 27px;
}

.left-menu > li {
  border-bottom: 1px solid #e7e7e6;
}

.left-menu li a {
  position: relative;
  color: #121111;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 46px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.left-menu ul {
  display: none;
}

.left-menu ul li {
  margin-bottom: 20px;
}

.left-menu ul li a {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 15px;
  font-weight: normal;
  color: #000000;
  text-decoration: underline;
  text-transform: none;
}

.left-menu li a:hover {
  color: #21632f;
  text-decoration: none;
}

.left-menu ul li:before {
  content: '- ';
}

.left-menu li.active > a {
  color: #21632f;
  text-decoration: none;
}

#cssmenu ul ul li.has-sub > a:after {
  border-left: 2px solid #eeeeee;
  border-top: 2px solid #eeeeee;
  content: "";
  display: block;
  height: 5px;
  position: absolute;
  right: 20px;
  top: 11.5px;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  width: 5px;
  z-index: 10;
}

.sidebar-social {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.sidebar-social-icon {
  position: relative;
  bottom: 0;
  display: inline-block;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  color: #4c4c4c;
  background-color: #e8eccd;
  border-radius: 5px;
  -webkit-transition: bottom .2s ease-out;
  transition: bottom .2s ease-out;
}

.sidebar-social-icon .fa {
  line-height: 30px;
}

.holder {
  height: 0;
  position: absolute;
  right: -40px;
  top: -14px;
  width: 0;
}

.holder:after {
  content: "";
  display: block;
  height: 6px;
  position: absolute;
  right: 20px;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  width: 6px;
  z-index: 10;
}

.holder:before {
  content: "";
  display: block;
  height: 6px;
  position: absolute;
  right: 20px;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  width: 6px;
  z-index: 10;
  border-left-color: inherit;
  border-left-style: solid;
  border-left-width: 2px;
  border-top-color: inherit;
  border-top-style: solid;
  border-top-width: 2px;
  top: 18px;
}

#content .l-col {
  box-sizing: content-box;
  padding: 0 20px 0 38px;
  padding-bottom: 430px;
}

#content .l-col p {
  font-size: 16px;
  line-height: 18px;
}

img,object,embed,video {
  max-width: 100%;
  height: auto;
  box-sizing: content-box;
}

table img {
  max-width: none;
}

video {
  width: 100% !important;
  height: auto !important;
}

@media (min-width: 0) {
  .tm,.f-nav {
    display: none;
  }

  #content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .l-col {
    display: none;
  }

  #head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  #head > div {
    margin: 0 auto;
  }

  .logo,.zakaz {
    border-bottom: 1px solid #e7e7e6;
  }

  .hbg-1 {
    display: none;
  }

  .hbg-3 {
    text-align: center;
  }

  .button {
    margin-bottom: 1em;
  }

  .slider {
    display: none;
  }

  main .menu li {
    width: 40%;
  }

  main h1,main h2 {
    text-align: center;
  }
}

@media (min-width: 30.1em) {
  .logo,.zakaz {
    border-bottom: 0;
  }

  .zakaz-btn {
    position: relative;
    top: 38px;
  }

  .hbg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .hbg-3 {
    height: 140px;
  }

  .button {
    margin-bottom: 0;
    margin-right: 16px;
  }

  .slider {
    display: block;
  }

  main h1,main h2 {
    text-align: left;
  }
}

@media (min-width: 48.1em) {
  #content {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  #head {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  #head > div {
    margin: 0;
  }

  .l-col {
    display: block;
  }

  .zakaz {
    width: 340px;
  }

  .zakaz li {
    font-size: 16px;
  }
}

@media (min-width: 64.1em) {
  .slicknav_menu {
    display: none;
  }

  .tm a {
    padding: 0 25px;
  }

  .tm,.f-nav {
    display: block;
  }

  main .menu li {
    width: 30%;
  }

  .hbg-1,.hbg-3 {
    display: block;
  }
}
